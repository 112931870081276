<template>
    <div>
        <b-list-group-item class="flex-column align-items-start plistgroup">
            <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">
                    {{ title }} 
                </h5>
            </div>

            <p class="mb-1 text-left">
                {{ body }}
            </p>

            <p class="text-md-left plink">
                Веб-сайт: <b-link :href="link">
                    {{ link }}
                </b-link>
            </p>
        </b-list-group-item>
    </div>
</template>

<script>
export default {
    name: "ProgramCard",
    props: {
        title: {
            type: String
        },
        body: {
            type: String
        },
        link: {
            type: String
        }
    }
};
</script>

<style scoped>
    .plink {
        margin-top: 10px;
        font-weight: 500;
    }
    .plistgroup {
        margin-bottom: 10px;
    }
</style>
