<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-lg-8 text-center">
                        <h3 class="display-4">
                            Ссылки
                        </h3>
                        <b-list-group>
                            <program-card
                                v-for="item in links"
                                :key="item.link"
                                :body="item.body"
                                :link="item.link"
                            />
                        </b-list-group>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import ProgramCard from './components/ProgramCard.vue';

export default {
    name: "Links",
    components: {
        ProgramCard
    },
    data () {
        return {
            links: [
                {
                    body: 'ноты для симфонического, народного и, духового',
                    link: 'https://noty-bratstvo.org/'
                },
                {
                    body: 'партитуры, библиотека',
                    link: 'http://blagovestnik.org'
                },
                {
                    body: '"Голос Истины" - духовно назидательный журнал',
                    link: 'http://istina.info/'
                },
                {
                    body: 'прогамма для высвечивания текстов на экране во время служения',
                    link: 'https://softprojector.org/'
                },
                {
                    body: 'Brass Project',
                    link: 'http://brassproject.org/'
                },
                {
                    body: 'Brass Choir',
                    link: 'http://brass-choir.org/'
                },
                {
                    body: 'Международая Благотворительная Миссия',
                    link: 'https://www.lighttotheworld.net/'
                }
            ]
        };
    }
};
</script>